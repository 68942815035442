<template>
    <div class="group-welcome" v-loading="loading">
        <div class="group-welcome-content">
            <div class="title">{{ list.welcome_name }}</div>
            <!-- 文本 -->
            <p class="text margin-b">{{ list.welcome_content }}</p>
            <div v-for="(item, index) in list.material_info" :key="index">
                <!-- 图片类型 -->
                <img
                    v-if="item.attach_type === 1"
                    class="type-img margin-b"
                    :src="item.attach_detail.pic_url"
                    alt=""
                    @click="getImg"
                />
                <!-- 网页 -->
                <div class="web-preview-box margin-b" v-else-if="item.attach_type === 2">
                    <a class="link-box" :href="item.attach_detail.url">
                        <div class="img">
                            <img :src="item.attach_detail.picurl || WEB_DEFAULT_IMG" alt="" />
                        </div>
                        <div class="text">
                            <div class="text-title">{{ item.attach_detail.title }}</div>
                            <div class="text-content">{{ item.attach_detail.desc }}</div>
                        </div>
                    </a>
                </div>
                <!-- 文件 -->
                <div class="file-box margin-b" v-else-if="item.attach_type === 3">
                    <a class="link-box" :href="item.attach_detail.file_url">
                        <div class="img">
                            <img src="./images/icon-excel.png" alt="" v-if="item.attach_detail.file_type === 'xlsx'" />
                            <img src="./images/icon-excel.png" alt="" v-if="item.attach_detail.file_type === 'xls'" />
                            <img src="./images/icon-pdf.png" alt="" v-if="item.attach_detail.file_type === 'pdf'" />
                            <img src="./images/icon-ppt.png" alt="" v-if="item.attach_detail.file_type === 'ppt'" />
                            <img src="./images/icon-ppt.png" alt="" v-if="item.attach_detail.file_type === 'pptx'" />
                            <img src="./images/icon-rar.png" alt="" v-if="item.attach_detail.file_type === 'rar'" />
                            <img src="./images/icon-rar.png" alt="" v-if="item.attach_detail.file_type === 'zip'" />
                            <img src="./images/icon-txt.png" alt="" v-if="item.attach_detail.file_type === 'txt'" />
                            <img src="./images/icon-word.png" alt="" v-if="item.attach_detail.file_type === 'docx'" />
                            <img src="./images/icon-word.png" alt="" v-if="item.attach_detail.file_type === 'doc'" />
                        </div>
                        <div class="text">
                            <div class="text-title">{{ item.attach_detail.file_name }}</div>
                            <div class="text-content">{{ item.attach_detail.file_size }}MB</div>
                        </div>
                    </a>
                </div>
                <!-- 小程序 -->
                <div class="margin-b" v-else-if="item.attach_type === 4">
                    <a class="link-box" :href="item.attach_detail.page">
                        <div class="img">
                            <img :src="item.attach_detail.picurl || APPLET_DEFAULT_IMG" alt="" />
                        </div>
                        <div class="text">
                            <div class="text-title">{{ item.attach_detail.title }}</div>
                        </div>
                    </a>
                </div>
                <!-- 视频 -->
                <div class="margin-b" v-else-if="item.attach_type === 5">
                    <video width="130" height="130" controls>
                        <source :src="item.attach_detail.video_url" type="video/mp4" ref="video" />
                        <source :src="item.attach_detail.video_url" type="video/avi" ref="video" />
                        <source :src="item.attach_detail.video_url" type="video/wmv" ref="video" />
                        <source :src="item.attach_detail.video_url" type="video/mov" ref="video" />
                        <source :src="item.attach_detail.video_url" type="video/flv" ref="video" />
                        <source :src="item.attach_detail.video_url" type="video/rmvb" ref="video" />
                        <source :src="item.attach_detail.video_url" type="video/3gp" ref="video" />
                        <source :src="item.attach_detail.video_url" type="video/m4v" ref="video" />
                        <source :src="item.attach_detail.video_url" type="video/mkv" ref="video" />
                    </video>
                </div>
                <!-- 表单 -->
                <div class="margin-b" v-else-if="item.attach_type === 6">
                    <a class="link-box" :href="item.attach_detail.url">
                        <div class="img">
                            <img :src="item.attach_detail.picurl" alt="" />
                        </div>
                        <div class="text">
                            <div class="text-title">{{ item.attach_detail.title }}</div>
                            <div class="text-content">{{ item.attach_detail.desc }}</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="group-welcome-tip">该欢迎语已上传至入群欢迎语素材库，请前往配置</div>
        <div class="group-welcome-way">
            <div>如何配置如群欢迎语？</div>
            <div @click="openWay" class="open-way">
                <van-icon name="question-o" color="#1472FF" size="16" />
            </div>
        </div>
    </div>
</template>

<script>
import { ImagePreview } from 'vant';
import { WEB_DEFAULT_IMG, APPLET_DEFAULT_IMG } from '@/constant';

export default {
    name: 'groupWelcome',
    data() {
        return {
            welcome_id: '',
            list: {},
            loading: false,
            imgsList: [],
            WEB_DEFAULT_IMG,
            APPLET_DEFAULT_IMG
        };
    },
    created() {
        this.getInfo();
    },
    methods: {
        getImg() {
            ImagePreview({
                images: this.imgsList,
                closeable: true
            });
        },
        openWay() {
            this.$router.push('/guideGroup');
        },
        getInfo() {
            const welcome_id = this.$route.query.welcome_id;
            this.$ajax('detail_welcome', { welcome_id: welcome_id })
                .then((res) => {
                    this.list = res.data.data;
                    this.loading = true;
                    let imgs = res.data.data.material_info;
                    imgs.forEach((item) => {
                        if (item.attach_type == 1) {
                            this.imgsList.push(item.attach_detail.pic_url);
                        }
                    });
                })
                .catch((err) => {
                    this.$message.warning(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.group-welcome {
    background: #f5f5f5;
    padding: 16px;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    .margin-b {
        margin-bottom: 8px;
    }
    .group-welcome-content {
        padding: 16px 16px 8px 16px;
        border-radius: 8px;
        background: #ffffff;
        margin-bottom: 12px;
        .title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 8px;
        }
        .type-img {
            width: 98px;
            height: 98px;
        }
        .link-box {
            display: block;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            border-radius: 8px;
            background: rgba(245, 245, 245, 1);
            .img {
                width: 24px;
                height: 24px;
                img {
                    width: 100%;
                    height: 100%;
                }
                margin-right: 16px;
            }
            .text-title {
                color: #333333;
            }
            .text-content {
                color: rgba(153, 153, 153, 1);
                font-size: 12px;
                margin-top: 4px;
            }
        }
    }
    .group-welcome-tip {
        color: rgba(153, 153, 153, 1);
        margin-bottom: 16px;
        font-size: 12px;
    }
    .group-welcome-way {
        color: rgba(102, 102, 102, 1);
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .open-way {
            height: 16px;
            cursor: pointer;
        }
    }
}
</style>
